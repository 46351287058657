var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c("BlockTitle", {
        staticClass: "blocktitle",
        attrs: { title: "自动打卡设置", "is-bold": true },
      }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "a-checkbox",
            {
              attrs: { checked: _vm.form.leaveAutoPunchFlag ? true : false },
              on: {
                change: function ($event) {
                  return _vm.onChange("leaveAutoPunchFlag")
                },
              },
            },
            [_vm._v(" 员工离职当天，自动补一个下班卡 ")]
          ),
        ],
        1
      ),
      _c("BlockTitle", {
        staticClass: "blocktitle",
        attrs: { title: "上下班打卡", "is-bold": true },
      }),
      _c(
        "div",
        { staticClass: "applets" },
        [
          _c("span", [_vm._v("打卡条件")]),
          _c(
            "a-select",
            {
              staticStyle: { width: "230px" },
              on: { change: _vm.changeCondition },
              model: {
                value: _vm.form.punchRuleType,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "punchRuleType", $$v)
                },
                expression: "form.punchRuleType",
              },
            },
            _vm._l(_vm.punch_rule_type, function (item) {
              return _c(
                "a-select-option",
                { key: item.label, attrs: { value: item.value } },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.form.punchRuleType !== "2",
              expression: "form.punchRuleType !=='2'",
            },
          ],
          staticClass: "applets",
        },
        [
          _c("span", [_vm._v("关联打卡地址")]),
          _c(
            "a-button",
            {
              staticClass: "commonIcon",
              attrs: { prefix: "add-one" },
              on: {
                click: function ($event) {
                  _vm.showAddress = true
                  _vm.outSideFlag = false
                },
              },
            },
            [_c("span", { staticClass: "addressIcon" }, [_vm._v("选择地址")])]
          ),
          _vm._l(_vm.addressList, function (item, i) {
            return _c(
              "div",
              { key: item.clockLocationId, staticClass: "punch" },
              [
                _c("p", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.locationName)),
                ]),
                _c("p", { staticClass: "text" }, [
                  _vm._v(_vm._s(item.gpsName)),
                ]),
                _c("div", { staticClass: "delete" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "iconpark-icon",
                      on: {
                        click: function ($event) {
                          return _vm.delAddress(i)
                        },
                      },
                    },
                    [_c("use", { attrs: { href: "#delete" } })]
                  ),
                ]),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.form.punchRuleType !== "1",
              expression: "form.punchRuleType !=='1'",
            },
          ],
          staticClass: "applets",
        },
        [
          _c("span", [_vm._v("关联打卡WIFI")]),
          _c(
            "a-button",
            {
              staticClass: "commonIcon",
              attrs: { prefix: "add-one" },
              on: {
                click: function ($event) {
                  _vm.showWifi = true
                },
              },
            },
            [_c("span", { staticClass: "addressIcon" }, [_vm._v("选择WIFI")])]
          ),
          _vm._l(_vm.wifiList, function (item, i) {
            return _c("div", { key: item.clockWifiId, staticClass: "punch" }, [
              _c("p", { staticClass: "name" }, [_vm._v(_vm._s(item.wifiName))]),
              _c("p", { staticClass: "text" }, [
                _vm._v(_vm._s(item.wifiBssId)),
              ]),
              _c("div", { staticClass: "delete" }, [
                _c(
                  "svg",
                  {
                    staticClass: "iconpark-icon",
                    on: {
                      click: function ($event) {
                        return _vm.deleteWifi(i)
                      },
                    },
                  },
                  [_c("use", { attrs: { href: "#delete" } })]
                ),
              ]),
            ])
          }),
        ],
        2
      ),
      _c("BlockTitle", {
        staticClass: "blocktitle",
        attrs: { title: "外勤打卡", "is-bold": true },
      }),
      _c(
        "a-checkbox",
        {
          attrs: { checked: _vm.form.allowOutSidePunchFlag ? true : false },
          on: {
            change: function ($event) {
              return _vm.onChange("allowOutSidePunchFlag")
            },
          },
        },
        [_vm._v(" 外勤打卡无需提交出差或者外出审批 ")]
      ),
      _c("br"),
      _c(
        "a-checkbox",
        {
          attrs: { checked: _vm.form.outSideTakePhotoFlag ? true : false },
          on: {
            change: function ($event) {
              return _vm.onChange("outSideTakePhotoFlag")
            },
          },
        },
        [_vm._v(" 员工必须拍照上传 ")]
      ),
      _c("br"),
      _c(
        "a-checkbox",
        {
          attrs: { checked: _vm.form.outSideLimitLocationFlag ? true : false },
          on: {
            change: function ($event) {
              _vm.onChange("outSideLimitLocationFlag")
              _vm.outSideList = []
            },
          },
        },
        [_vm._v(" 有指定的外勤打卡地址 ")]
      ),
      _c("br"),
      _vm.form.outSideLimitLocationFlag
        ? _c(
            "div",
            [
              _c(
                "a-button",
                {
                  staticClass: "chooseAddress",
                  attrs: { prefix: "add-one" },
                  on: {
                    click: function ($event) {
                      _vm.showOut = true
                    },
                  },
                },
                [_c("span", { staticClass: "address" }, [_vm._v("选择地址")])]
              ),
              _vm._l(_vm.outSideList, function (item, i) {
                return _c("div", { key: i, staticClass: "punch notFlag" }, [
                  _c("p", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.locationName)),
                  ]),
                  _c("p", { staticClass: "text" }, [
                    _vm._v(_vm._s(item.gpsName)),
                  ]),
                  _c("div", { staticClass: "delete" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "iconpark-icon",
                        on: {
                          click: function ($event) {
                            return _vm.deloutSideList(i)
                          },
                        },
                      },
                      [_c("use", { attrs: { href: "#delete" } })]
                    ),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _c("checkPunch", {
        attrs: {
          type: "address",
          show: _vm.showAddress,
          options: _vm.addressList,
        },
        on: { cancel: _vm.cancel, address: _vm.getAddressList },
      }),
      _c("checkPunch", {
        attrs: { type: "wifi", show: _vm.showWifi, options: _vm.wifiList },
        on: { cancel: _vm.cancel, address: _vm.getWifiList },
      }),
      _c("checkPunch", {
        attrs: {
          type: "address",
          show: _vm.showOut,
          "out-side-flag": true,
          options: _vm.outSideList,
        },
        on: { cancel: _vm.cancel, address: _vm.getoutSideList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }