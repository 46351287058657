var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-content" }, [
    _c("header", { staticClass: "page-header" }, [
      _c(
        "div",
        {
          staticClass: "cancle",
          on: {
            click: function ($event) {
              return _vm.cancelPrev()
            },
          },
        },
        [
          _c("a-button", { attrs: { type: "iconBtn", icon: "left" } }),
          _c("span", { staticClass: "title" }, [
            _vm._v(
              _vm._s(
                _vm.type === "edit"
                  ? "编辑"
                  : _vm.type === "copy"
                  ? "复制"
                  : "新增"
              ) + "考勤组"
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "steps-box" },
        [
          _c(
            "a-steps",
            {
              attrs: {
                current: _vm.current,
                size: "small",
                type: "navigation",
              },
              on: { change: _vm.stepChange },
            },
            [
              _c("a-step", {
                attrs: {
                  title: "基本信息",
                  status:
                    _vm.current === 0
                      ? "process"
                      : _vm.infoflag
                      ? "finish"
                      : "wait",
                },
              }),
              _c("a-step", {
                attrs: {
                  title: "排班日历",
                  status:
                    _vm.current === 1
                      ? "process"
                      : _vm.sehedflag
                      ? "finish"
                      : "wait",
                },
              }),
              _c("a-step", {
                attrs: {
                  title: "考勤规则",
                  status:
                    _vm.current === 2
                      ? "process"
                      : _vm.ruleflag
                      ? "finish"
                      : "wait",
                },
              }),
              _c("a-step", {
                attrs: {
                  title: "打卡设置",
                  status:
                    _vm.current === 3
                      ? "process"
                      : _vm.setflag
                      ? "finish"
                      : "wait",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "solt-content" }, [
      _c(
        "div",
        { staticClass: "steps-content Jcommon-layout-main Jflex-main" },
        [
          _c("BasicInformation", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current === 0,
                expression: "current === 0",
              },
            ],
            ref: "BasicInformation",
            attrs: { type: _vm.type, data: _vm.form },
            on: {
              changeType: _vm.changeType,
              getData: _vm.basicInformation,
              resetData: _vm.resetData,
            },
          }),
          _c("ScheduleCalendar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current === 1,
                expression: "current === 1",
              },
            ],
            ref: "ScheduleCalendar",
            attrs: { data: _vm.form },
            on: { getData: _vm.scheduleCalendar, resetData: _vm.resetData },
          }),
          _c("Attendancerules", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current === 2,
                expression: "current === 2",
              },
            ],
            ref: "Attendancerules",
            attrs: { data: _vm.form },
            on: { getData: _vm.attendancerules, resetData: _vm.resetData },
          }),
          _c("ClockSetting", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current === 3,
                expression: "current === 3",
              },
            ],
            ref: "ClockSetting",
            attrs: { type: _vm.type, formdata: _vm.form },
            on: { getData: _vm.clockSetting },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "steps-action" },
        [
          _vm.current > 0
            ? _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "12px" },
                  on: { click: _vm.prev },
                },
                [_vm._v(" 上一步 ")]
              )
            : _vm._e(),
          _vm.current !== 3
            ? _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.next } },
                [_vm._v(" 下一步 ")]
              )
            : _vm._e(),
          _vm.current == 3
            ? _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.add } },
                [_vm._v(" 保存 ")]
              )
            : _vm._e(),
          _vm.current == 3 && _vm.type === "edit"
            ? _c(
                "a-tooltip",
                { attrs: { placement: "topRight", trigger: "hover" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", [_vm._v("修改点将于明日生效")]),
                  ]),
                  _c("a-icon", {
                    staticClass: "exclamation-circle",
                    attrs: { type: "exclamation-circle", theme: "filled" },
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }