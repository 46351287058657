var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "排班日历名称", prop: "shiftSchedulingId" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择排班日历" },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.form.shiftSchedulingId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "shiftSchedulingId", $$v)
                    },
                    expression: "form.shiftSchedulingId",
                  },
                },
                _vm._l(_vm.shiftList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.shiftSchedulingId,
                      attrs: { value: item.shiftSchedulingId },
                    },
                    [_vm._v(" " + _vm._s(item.shiftSchedulingName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.shiftSchedulingId,
                  expression: "form.shiftSchedulingId",
                },
              ],
            },
            [
              _c("a-form-model-item", { attrs: { label: "排班日历类型" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.detail.shiftCycleType === "1"
                        ? "按周排班"
                        : _vm.detail.shiftCycleType === "2"
                        ? "按月排班"
                        : "无固定休息日（" +
                          _vm.detail.cycleDays +
                          "天循环排班）"
                    ) +
                    " "
                ),
              ]),
              _c("a-form-model-item", { attrs: { label: "出勤日" } }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.detail.workCycleSequenceStr),
                  },
                }),
              ]),
              _c("a-form-model-item", { attrs: { label: "同步法定节假日" } }, [
                _vm._v(
                  " " + _vm._s(_vm.detail.syncHolidayFlag ? "是" : "否") + " "
                ),
              ]),
              _c("a-form-model-item", { attrs: { label: "特殊日期列表" } }, [
                _vm.detail.specialDays && _vm.detail.specialDays.length > 0
                  ? _c(
                      "div",
                      _vm._l(_vm.detail.specialDays, function (item, index) {
                        return _c("p", { key: index }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .moment(item.specialDate)
                                  .format("YYYY-MM-DD")
                              ) +
                              " "
                          ),
                        ])
                      }),
                      0
                    )
                  : _c("div", [_vm._v(" 无 ")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }