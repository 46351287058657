var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              ref: "overTimeRuleId",
              attrs: { label: "加班规则", prop: "overTimeRuleId" },
            },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择加班规则" },
                  model: {
                    value: _vm.form.overTimeRuleId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "overTimeRuleId", $$v)
                    },
                    expression: "form.overTimeRuleId",
                  },
                },
                _vm._l(_vm.overTimeRuleList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.overtimeRuleId,
                      attrs: { value: item.overtimeRuleId },
                    },
                    [_vm._v(" " + _vm._s(item.ruleName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              ref: "outSideRuleId",
              attrs: { label: "外勤规则", prop: "outSideRuleId" },
            },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择外勤规则" },
                  model: {
                    value: _vm.form.outSideRuleId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "outSideRuleId", $$v)
                    },
                    expression: "form.outSideRuleId",
                  },
                },
                _vm._l(_vm.outSideRuleList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.outsideRuleId,
                      attrs: { value: item.outsideRuleId },
                    },
                    [_vm._v(" " + _vm._s(item.ruleName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              ref: "appendPunchRuleId",
              attrs: {
                label: "补卡规则",
                prop:
                  _vm.form.attendanceGroupType === "2"
                    ? "appendPunchRuleId"
                    : "",
              },
            },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择补卡规则", "allow-clear": "" },
                  model: {
                    value: _vm.form.appendPunchRuleId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "appendPunchRuleId", $$v)
                    },
                    expression: "form.appendPunchRuleId",
                  },
                },
                _vm._l(_vm.appendPunchList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.appendPunchRuleId,
                      attrs: { value: item.appendPunchRuleId },
                    },
                    [_vm._v(" " + _vm._s(item.ruleName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: " ", "wrapper-col": { span: 14 } } },
            [
              _c("span", { staticClass: "model-item-span" }, [
                _vm._v(
                  "注：考勤扣款规则、考勤补贴规则可在【薪酬】- 【设置】-【考勤】中设定"
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }