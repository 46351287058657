var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            width: "600px",
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              ref: "attendanceGroupName",
              attrs: { label: "考勤组名称", prop: "attendanceGroupName" },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入名称1-30个字符",
                  "max-length": 30,
                },
                model: {
                  value: _vm.form.attendanceGroupName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "attendanceGroupName", $$v)
                  },
                  expression: "form.attendanceGroupName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              ref: "attendanceGroupType",
              attrs: { label: "考勤组类型" },
              on: { change: _vm.changeType },
            },
            [
              _c("a-radio-group", {
                attrs: { options: _vm.attendance_group_type },
                model: {
                  value: _vm.form.attendanceGroupType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "attendanceGroupType", $$v)
                  },
                  expression: "form.attendanceGroupType",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              ref: "attendanceGroupStaff",
              attrs: { label: "选择适用人员", prop: "attendanceGroupStaff" },
            },
            [
              _c("CbSelectPersons", {
                attrs: { "data-echo": _vm.perDataList },
                on: { perModelConfirm: _vm.perModelConfirm },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { ref: "type", attrs: { label: "工时制类型" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { disabled: _vm.type === "edit" },
                  on: { change: _vm.changeLaborTimeType },
                  model: {
                    value: _vm.form.laborTimeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "laborTimeType", $$v)
                    },
                    expression: "form.laborTimeType",
                  },
                },
                _vm._l(_vm.labor_time_type, function (item) {
                  return _c(
                    "a-radio",
                    {
                      key: item.value,
                      attrs: {
                        disabled: item.value === "2",
                        value: item.value,
                      },
                    },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.laborTimeType === "2"
            ? _c(
                "a-form-model-item",
                {
                  ref: "type",
                  attrs: { label: "工时核算周期", prop: "laborTimeCycle" },
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.form.laborTimeCycle,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "laborTimeCycle", $$v)
                        },
                        expression: "form.laborTimeCycle",
                      },
                    },
                    _vm._l(_vm.labor_time_cycle, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.value,
                          attrs: { "slot-scope": item.value },
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            {
              ref: "times",
              attrs: { label: "考勤标准时长", prop: "standardHours" },
            },
            [
              _c("NumberInput", {
                attrs: {
                  "decimal-separator": true,
                  placeholder: "请输入考勤标准时长",
                  type: "number",
                  max: "24",
                  suffix: "小时/天",
                },
                model: {
                  value: _vm.form.standardHours,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "standardHours", $$v)
                  },
                  expression: "form.standardHours",
                },
              }),
              _c("p", [_vm._v("用于请假/加班/出差的天数，小时数之间的折算")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }